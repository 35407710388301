<template lang="pug">
.column.relative_position_materials_search
  .equipment-tech-map
    .tech-map-card
      .tabs-block
        table#components-table.tech-map-tab-table.components-table.stores
          thead
            tr
              th(@click="changeSortParams('user')")
                span.column-header-title {{ tabTitles.user }}
                  q-icon(:name="iconName()", v-if="activeSort === 'user'")
              th(@click="changeSortParams('material_title')")
                span.column-header-title {{ tabTitles.material_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'material_title'")
              th(@click="changeSortParams('count')")
                span.column-header-title {{ tabTitles.count }}
                  q-icon(:name="iconName()", v-if="activeSort === 'count'")
              th(@click="changeSortParams('cost')")
                span.column-header-title {{ tabTitles.cost }}
                  q-icon(:name="iconName()", v-if="activeSort === 'cost'")
              th(@click="changeSortParams('issue')")
                span.column-header-title {{ tabTitles.issue }}
                  q-icon(:name="iconName()", v-if="activeSort === 'issue'")
              th(@click="changeSortParams('created_at')")
                span.column-header-title {{ tabTitles.created_at }}
                  q-icon(:name="iconName()", v-if="activeSort === 'created_at'")
              th(@click="changeSortParams('store_sender_title')")
                span.column-header-title {{ tabTitles.store_sender_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'store_sender_title'")
              th(@click="changeSortParams('store_motion_title')")
                span.column-header-title {{ tabTitles.store_motion_title }}
                  q-icon(:name="iconName()", v-if="activeSort === 'store_motion_title'")
              th(@click="changeSortParams('demand_date')")
                span.column-header-title {{ tabTitles.demand_date }}
                  q-icon(:name="iconName()", v-if="activeSort === 'demand_date'")
          tbody(, v-infinite-scroll="loadNextPage", infinite-scroll-distance="20")
            template(v-if="tab_data[0].data.length")
              tr(
                v-for="item in tab_data[0].data",
                :key="item.material_title + item.issue_id + item.cost + item.count + item.created_at"
              )
                td {{ item.user }}
                td {{ item.material_title }}
                td {{ item.count }}
                td {{ item.cost }}
                td.consumptions-history__issue_cell(@click="goToIssue(item.issue_id)") {{ `№ ${item.issue_id}` }}
                td {{ formatDate(item.created_at) }}
                td {{ item.store_sender_title }}
                td {{ item.store_motion_title }}
                td {{ getDate(item.demand_date) }}
            template(v-else)
              tr
                td.text-center(colspan="6") {{ notifies.data_not_found }}
</template>

<script>
import techMapInput from "./helpers/input";
import handleButtons from "./helpers/buttons.vue";
import { navbarLocales } from "@/services/useLocales";
import { parseISO, format } from "date-fns";

export default {
  components: {
    techMapInput,
    handleButtons,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      row_id: this.parentData.row_id,
      tab_data: this.parentData.data,
      checkable: undefined,
      tech_map_key: "components",
      searchFieldValue: "",
      activeSort: "id",
      activeDirection: "down",
      page: 1,
    };
  },

  computed: {
    data() {
      let data = this.parentData.data;
      return data ? data : [];
    },

    buttons() {
      return this.parentData.buttons;
    },

    searchFieldLabel() {
      return navbarLocales.search;
    },

    tabTitles() {
      return this.tab_data[0].titles;
    },
  },

  mounted() {
    this.$emit("load-page", {
      page: 1,
    });
  },

  methods: {
    goToIssue(id) {
      this.$router.push({
        name: "issues",
        query: {
          show: true,
          id: id,
        },
      });
    },

    formatDate(date) {
      return format(new Date(date), "dd.MM.yyyy, HH:mm");
    },

    onClickFn(button) {
      this[button.onclick]();
    },

    resetForm() {
      this.searchFieldValue = "";
      this.searchAndSort();
    },

    iconName() {
      return this.activeDirection === "up" ? "expand_less" : "expand_more";
    },

    isCountOrCost(key) {
      return key === "count" || key === "cost";
    },

    changeSortParams(val) {
      if (val === this.activeSort) {
        if (this.activeDirection === "down") {
          this.activeDirection = "up";
        } else {
          this.activeDirection = "down";
        }
      } else {
        this.activeSort = val;
        this.activeDirection = "down";
      }

      this.searchAndSort();
    },

    searchAndSort() {
      this.$emit("search-or-sort", {
        sort: this.activeSort,
        sort_direction: this.activeDirection,
        search: this.searchFieldValue,
      });
    },

    loadNextPage() {
      this.page++;
      console.log(this.page);
      this.$emit("load-page", {
        page: this.page,
      });
    },

    getDate(date) {
      const isoDate = parseISO(date);
      if (isoDate.toString() !== "Invalid Date") {
        return format(isoDate, "dd.MM.yyyy, HH:mm");
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/show/ppr/equipment/main";
.relative_position_materials_search {
  .search-all-filter-field {
    position: relative;
    top: -55px;
    margin-bottom: 40px;
    width: 50%;
    .equipment-tech-map {
      margin-top: 15px;
    }
  }
}

.consumptions-history__issue_cell {
  cursor: pointer;
  color: #5b7aff !important;
  text-decoration: underline;
}
</style>
