<template lang="pug">
#store.equipment-tech-map.materials-tab
  .spinner-container.form-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  .tech-map-card(v-if="!loading")
    .main-block
      .actions-block
        .show-card-actions
          div(v-for="button in buttons", :class="'button-wrapper ' + button.name", :style="button.style.wrapper")
            q-btn.show-card-action(flat, no-caps, @click="updateButtonStyle(button)", :style="button.style.wrapper")
              template(v-if="ifButtonIconExists(button)", slot="default")
                inline-svg(:class="[button.name + '-icon']", :style="button.style.icon", :src="getButtonIcon(button)")
              template(v-if="ifButtonLabelExists(button)", slot="default")
                span.action-label {{ button.label }}
          q-btn.materials-import-button(
            @click="$refs['grid-modals'].openImportForm({ data: [], href: '' })",
            flat,
            no-caps
          )
            template(slot="default")
              inline-svg.grid-btn-icon-left(:src="fileIcon")
            template(slot="default", v-if="row && row.materials_list")
              span {{ row.materials_list.import_button.button_label }}
          q-btn.materials-import-button(@click="openExportForm()", flat, no-caps)
            template(slot="default")
              inline-svg.grid-btn-icon-left(:src="fileIcon")
            template(slot="default", v-if="row?.materials_list")
              span {{ exportExcelText }}

    .techmap-info-blocks
      .spinner-container.form-spinner(v-if="loading_blocks")
        q-spinner(color="primary", size="3em")

      .specification-block(v-if="!loading_blocks")
        specification(
          v-if="row && row.materials_list",
          :parentData="{ buttons: data['edit_materials_list_buttons'], tab_conf: tab.data, editable, grid, row_id, data: row && row.materials_list ? row.materials_list.materials_table : [] }",
          @search-or-sort="reloadTable"
        )

    .button-group.row.justify-center(v-if="editable")
      q-btn.tech-map-form-submit(
        flat,
        no-caps,
        :label="data['edit_materials_list_buttons']['submit']['label']",
        @click="onSubmit"
      )
  grid-modals(
    ref="grid-modals",
    @reload-data="reloadTable",
    :parentData="{ grid: grid, path: `/api/v3/stores/${parentData.row.id}/materials_stores` }"
  )
  q-dialog(v-model="confirmModal")
    confirm-modal(@confirm="onConfirmExport", :parentData="{ message: notifies.confirm_excel_export }")
</template>

<script>
import specification from "../../specification/specification";
import ImportExcelForm from "@/components/shared/forms/importExcelForm";
import gridModals from "@/components/shared/grid/gridModals";
import confirmModal from "@/components/shared/helpers/confirmModal";
import { handleError } from "@/services/handleErrors";
import { currentTimezoneOffset } from "@/services/useTimezoneHelpers";
import i18n from "@/plugins/vue-i18n";

export default {
  components: {
    ImportExcelForm,
    specification,
    gridModals,
    confirmModal,
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      path: this.parentData.path,
      grid: this.parentData.grid,
      item: "stores",
      row_id: this.parentData.row_id,
      tab_name: this.parentData.tab_name,
      row: {},
      data: [],
      editable: false,
      loading: true,
      loading_blocks: false,
      tab: "",
      confirmModal: false,
    };
  },

  computed: {
    exportExcelText() {
      return i18n.messages[this.current_locale]["exportExcel"];
    },

    buttons() {
      return this.data.actions;
    },

    fileIcon() {
      return require(`../../../../../assets/icons/header/file.svg`);
    },

    tech_map_data() {
      let grid = this.$store.state.grid[this.grid];
      if (grid) {
        return grid["materials_list"]["components"];
      } else {
        return undefined;
      }
    },
  },

  created() {
    this.$store.commit("initialStoringMaterials", { grid_name: this.grid });
    this.loadData();
  },

  methods: {
    openExportForm() {
      this.confirmModal = true;
    },

    async onConfirmExport() {
      if (localStorage.getItem("report_id_waiting")) {
        this.$q.notify(this.notifies.wait_report_generated);
      } else {
        await this.sendReport();
      }
    },

    async sendReport() {
      try {
        const reportParams = {
          filters: {
            store_id: this.row_id,
          },
          report_class: "material_store",
        };
        const params = {
          report_params: JSON.stringify(reportParams),
          time_zone: currentTimezoneOffset(),
        };

        const { data } = await this.$backend.create("/api/v3/reports", null, { report: params });

        this.$root.$emit("init-report-intervals", data);
        this.$q.notify(this.notifies.report_in_process);
      } catch (error) {
        await handleError(error);
      } finally {
        this.confirmModal = false;
      }
    },

    tabCondition(tab_name) {
      return tab_name !== "issues" || (tab_name === "issues" && this.getData(tab_name).length > 0);
    },

    getButtonIcon(button) {
      return require(`../../../../../assets/icons/ppr/equipment/show/actions/${button.icon}`);
    },

    ifButtonIconExists(button) {
      return button.icon && button.icon.length > 0;
    },

    ifButtonLabelExists(button) {
      return button.label && button.label.length > 0;
    },

    getData(tab_name) {
      if (tab_name) {
        if (tab_name === "issues") {
          return this.row[tab_name];
        } else {
          return this.row["technological_map"][tab_name];
        }
      }
    },

    onSubmit(params = {}) {
      const req = this.tech_map_data.map(item => ({
        id: item.id,
        cost: item.cost,
        count: item.count,
        store_sender_title: item.store_sender_title,
        store_motion_title: item.store_motion_title,
      }));

      params[this.item] = {
        materials_store: JSON.stringify(req),
      };

      this.loading = true;

      this.$backend
        .update("/api/v3/stores/" + this.row.id + "/materials_stores/update_records", "", params)
        .then(({ data }) => {
          this.row = data;
          this.editable = false;
          this.loading = false;

          this.loadData();
          this.reloadTable();

          this.$q.notify(this.notifies.success_updated_entry);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
          this.loading = false;
          this.$q.notify(this.notifies.error_ask_admin);
        });
    },

    addCurrentTab(tab) {
      this.tab = tab;
    },

    updateButtonStyle(button) {
      if (button.onclick && button.onclick.child) {
        this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
        this.buttons.find(b => b.name === button.onclick.child).style.wrapper.display = "initial";
      } else if (button.onclick && button.onclick.parent) {
        this.buttons.find(b => b.name === button.name).style.wrapper.display = "none";
        this.buttons.find(b => b.name === button.onclick.parent).style.wrapper.display = "initial";
      }

      if (button.onclick && button.onclick.fn_name) {
        this[button.onclick.fn_name](button.onclick.attr);
      }
    },

    setEditable(attr) {
      this.editable = attr.value;
      if (!attr.value) {
        this.loading_blocks = true;
        this.loadData();
      }
    },

    reloadTable(params = false) {
      let searchString = `/api/v3/stores/${this.parentData.row.id}/materials_stores/collection?event=import_excel`;
      if (params) {
        const descending = params.sort_direction === "down";
        const getParams = `{"table":{"rowsPerPage":20,"sortBy":"${params.sort}","descending":${descending},"query":"${params.search}","filters":{"store_id":${this.parentData.row.id}}}}`;

        searchString = "/api/v3/stores/" + this.parentData.row.id + "/materials_stores" + "?json=" + getParams;
      }

      this.$backend.index(searchString).then(({ data }) => {
        if (params) {
          if (this.row && this.row.materials_list) this.row.materials_list.materials_table[0].data = data.data;
        } else {
          if (this.row && this.row.materials_list) this.row.materials_list.materials_table[0].data = data.options;
        }
      });
    },

    loadData(params = {}) {
      this.$backend
        .show(this.path, this.row_id, { params: params })
        .then(({ data }) => {
          this.row = data["common"]["data"];
          this.data = data["common"]["tabs"].find(tab => tab.name === this.tab_name)["data"];
          this.tab = this.data.tabs[0];

          this.$nextTick(() => {
            let keys = Object.keys(this.row.materials_list);
            if (keys.length > 0) {
              keys.forEach(key => {
                if (key === "materials_list") {
                  if (this.row && this.row["materials_table"][key].map(s => s.required).length > 0) {
                    this.row["materials_table"][key].map(s => delete s.required);
                  }
                  if (this.row && this.row["materials_table"][key].filter(el => el.name).length > 0) {
                    this.row["materials_table"][key] = this.rewriteKeysTempLogic(this.row["technological_map"][key]);
                  }
                }

                this.$store.commit("addStoringMaterials", {
                  grid_name: this.grid,
                  tech_map_key: key,
                  attr:
                    this.row && this.row["materials_list"]
                      ? this.row["materials_list"]["materials_table"][0]["data"]
                      : [],
                });
              });
            }
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.loading = false;
          this.loading_blocks = false;
        });
    },

    rewriteKeysTempLogic(obj) {
      return obj.map(el => {
        return Object.keys(el).reduce((r, key) => {
          if (key === "name") {
            r["key"] = el[key];
          } else {
            r[key] = el[key];
          }
          return r;
        }, {});
      });
    },
  },
};
</script>

<style lang="scss">
#store .techmap-info-blocks,
#store .equipment-tech-map,
#store .tabs-block {
  min-height: 0;
}

#store {
  .specification-title span {
    font-size: 20px !important;
    color: var(--tab-text-color) !important;
  }
  &.equipment-tech-map .tech-map-card .specification-block {
    margin-top: 58px;
  }
  .equipment-tech-map {
    border-bottom: var(--tabs-block-td-border) !important;
    border-right: var(--tabs-block-td-border) !important;
    position: relative;
    .tech-map-card {
      .tabs-block {
        max-height: calc(66vh - 100px);
        margin-top: 0;
        td {
          padding: 10px;
        }
        thead {
          position: absolute;
          display: table;
          table-layout: fixed;
          top: -40px;
        }
        tr {
          display: table;
          table-layout: fixed;
          width: 100%;
        }
      }
    }
  }
}

.materials-import-button {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #5b7aff;
  color: #fff;
}

#components-table.stores td {
  color: var(--tab-text-color);
}

.equipment-tech-map.materials-tab {
  .tech-map-card .actions-block {
    padding-top: 0 !important;
    padding-left: 0 !important;
    width: 100% !important;
  }
}
</style>
